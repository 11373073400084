import { Title, Section } from "@vshp/ui-components";
import { Image } from "antd";
import Slider from "../utils/Slider";

import certificatesPicture1 from "../../../public/images/certificates/certificates_01.jpg";
import certificatesPicture2 from "../../../public/images/certificates/certificates_02.jpg";
import certificatesPicture3 from "../../../public/images/certificates/certificates_03.jpg";
import certificatesPicture4 from "../../../public/images/certificates/certificates_04.jpg";
import certificatesPicture5 from "../../../public/images/certificates/certificates_05.jpg";
import certificatesPicture6 from "../../../public/images/certificates/certificates_06.jpg";

const certificates = [
  {
    src: certificatesPicture4.src,
  },
  {
    src: certificatesPicture1.src,
  },
  {
    src: certificatesPicture2.src,
  },
  {
    src: certificatesPicture3.src,
  },
  {
    src: certificatesPicture5.src,
  },
  {
    src: certificatesPicture6.src,
  },
];

const Certificates = () => {
  const settings = {
    infinite: true,
  };

  return (
    <Section border={true}>
      <Title type="h2">Документы об образовании</Title>
      <div className="wysiwyg">
        <p>
          Выпускники института получают диплом бакалавра или магистра, диплом о&nbsp;профессиональной переподготовке или свидетельство о&nbsp;повышении квалификации&nbsp;&mdash; в&nbsp;зависимости
          от&nbsp;программы обучения.
        </p>
      </div>
      <div className="slider-certificates">
        <Slider className="slider__dots_accent" sliderSettings={settings}>
          {certificates.map((item, key) => (
            <div className="slider__item" key={key}>
              <div className="slider-certificates__item">
                <div className="slider-certificates__img-wrap">
                  <Image src={item.src} alt="Документ" />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </Section>
  );
};

export default Certificates;
