import { Title } from "@vshp/ui-components";
import Slider from "../utils/Slider";
import reviewsBg from "../../../public/images/reviews.jpg";
import { useSelector } from "react-redux";

import Image from "next/image";

function Reviews() {
  const { reviews } = useSelector((state) => state.reviews);

  const settings = {
    infinite: true,
  };

  return (
    reviews.length > 0 && (
      <section className="page__block page__block_cover">
        <Image className="page__block-img" src={reviewsBg} placeholder="blur" alt="ОТЗЫВЫ" />
        <div className="page__block-body">
          <Title type="h2" decor={false}>
            ОТЗЫВЫ
          </Title>

          <Slider className="slider-description" sliderSettings={settings}>
            {reviews.map((teacher, key) => (
              <div className="slider__item" key={key}>
                <div className="slider-description__item">
                  <div className="slider-description__header">
                    <img className="slider-description__photo" src={teacher.attributes.photo.origin} alt={teacher.attributes.name} />
                    <div className="slider-description__name">{teacher.attributes.name}</div>
                    <div className="slider-description__post" dangerouslySetInnerHTML={{ __html: teacher.attributes.description }}></div>
                  </div>
                  <div className="slider-description__content" dangerouslySetInnerHTML={{ __html: teacher.attributes.content }}></div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>
    )
  );
}

export default Reviews;
